.loader-main {
  width: 100%;
  height: 100vh;
  position: relative;
  background-color: white;
  z-index: 999;
}
.loader-span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid red;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  animation-name: loader;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

@keyframes loader {
  0% {
    width: 200px;
    height: 200px;
  }
  50% {
    width: 300px;
    height: 300px;
  }
  100% {
    width: 200px;
    height: 200px;
  }
}
